import React from "react"
import { graphql } from "gatsby"
import PostLink from "../components/post-link"
import Layout from '../components/Layout'
import SEO from '../components/seo'

const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  const Posts = edges
    // .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  const siteTitle = "Blogpage"

  return (
        <Layout title={siteTitle}>
            <SEO title={siteTitle} description={''} />
             <section className="w-full max-w-3xl mx-auto mt-16 antialiased">
          
               {Posts}
             </section>
        </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query {
        site {
        siteMetadata {
                title
                author
            }
         }
        allMdx(sort: {order: DESC, fields: frontmatter___date}, filter: {fields: {sourceName: {eq: "blog"}}, frontmatter: {published: {eq: true}} }) {
            edges {
                node {
                    id
                    frontmatter {
                        # date(formatString: "DD.MM.YYYY - hh:mm")
                        date(formatString: "DD. MMMM YYYY", locale: "de")
                        title
                        published
                        description
                        banner {
                          childImageSharp {
                            sizes(maxWidth: 720) {
                              ...GatsbyImageSharpSizes
                            }
                            fluid(quality: 90, maxWidth: 720) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                    }
                    fields {
                        slug
                        title
                        date(formatString: "DD.MM.YYYY - hh:mm")
                        changeDate(formatString: "DD.MM.YYYY - hh:mm")
                    }
                        excerpt(pruneLength: 200)
                    }
                }
        }

}`;