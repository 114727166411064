import React,{Fragment}  from "react"
import { Link } from "gatsby"
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import {IoIosCalendar} from 'react-icons/io'

const PostLink = ({ post }) => (
    <Link to={post.fields.slug} className="block mx-2 mb-4">
      {/* <article className="w-full max-w-full md:flex">
          <BackgroundImage 
              Tag="div"
              className="flex-none w-full h-48 overflow-hidden text-center md:h-auto md:w-64"
              fluid={post.frontmatter.banner.childImageSharp.fluid}
              >
              </BackgroundImage>
          <div className="flex flex-col justify-center p-4 leading-normal sm:h-36 sm:my-4">
            <p className="pb-2 text-sm font-black uppercase text-gradient">{post.frontmatter.date}</p>
            <h2 
              className="pb-2 mt-0 mb-0 text-4xl font-black leading-none tracking-tight uppercase sm:text-5xl md:pb-4 sm:pt-0 title-hover">{post.frontmatter.title}</h2>
          </div>
      </article> */}

      <article className="nicelink">
          {/* <div className=""> */}
            <h2 className="mb-0 text-4xl font-bold leading-none uppercase sm:text-5xl">
            {post.frontmatter.title}
            </h2>
            {/* <p className="pb-0 text-sm font-black uppercase">{post.frontmatter.date}</p> */}
            <p className="p-1 text-sm font-black uppercase">{post.frontmatter.date}</p>
          {/* </div> */}
      </article>
    </Link>
)


export default PostLink